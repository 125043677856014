import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerDetails, useCustomerLogout } from "../../../../hooks/useCustomer"

export const withDetailsForm = Component => ({ name = "DetailsForm" }) => {
  const [password, setPassword] = useState("")
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [showPasswordChangedPopup, setShowPasswordChangedPopup] = useState(false)

  const { customer, setCustomer, updateCustomer, saving, errors } = useCustomerDetails()
  const { logoutCustomer } = useCustomerLogout()

  const { dashboard } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_DASHBOARD_FORM {
      dashboard: sanityPageAccountDashboard {
        additionalFirstNamePlaceholder
        additionalLastNamePlaceholder
        additionalEmailPlaceholder
        additionalPhonePlaceholder
        additionalPasswordPlaceholder
        additionalPasswordLabel
        additionalSubmitButton
        additionalSubmittingButton
        additionalPasswordChangedTitle
        additionalPasswordChangedDescription
        additionalPasswordChangedButtonText
      }
    }
  `)

  const {
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalEmailPlaceholder,
    additionalPhonePlaceholder,
    additionalPasswordPlaceholder,
    additionalSubmitButton,
    additionalSubmittingButton,
    additionalPasswordChangedTitle,
    additionalPasswordChangedDescription,
    additionalPasswordChangedButtonText,
    additionalPasswordLabel,
  } = dashboard || {}

  const handleSubmit = async event => {
    event.preventDefault()
    setPasswordChanged(false)
    const data = password ? { ...customer, password } : { ...customer }
    await updateCustomer(data)
    if (password) {
      setPasswordChanged(true)
    }
  }

  const handleChange = ({ target: { name, value } }) =>
    setCustomer(prevState => ({
      ...prevState,
      [name]: value,
    }))

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value)
  }

  useEffect(() => {
    if (errors?.length === 0 && passwordChanged) {
      setShowPasswordChangedPopup(true)
    }
  }, [passwordChanged, errors])

  const handlePasswordChangedPopup = value => {
    setShowPasswordChangedPopup(value)
    logoutCustomer()
  }

  Component.displayName = name
  return (
    <Component
      customer={customer}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      password={password}
      handlePasswordChange={handlePasswordChange}
      saving={saving}
      errors={errors}
      showPasswordChangedPopup={showPasswordChangedPopup}
      handlePasswordChangedPopup={handlePasswordChangedPopup}
      additionalFirstNamePlaceholder={additionalFirstNamePlaceholder}
      additionalLastNamePlaceholder={additionalLastNamePlaceholder}
      additionalEmailPlaceholder={additionalEmailPlaceholder}
      additionalPhonePlaceholder={additionalPhonePlaceholder}
      additionalPasswordPlaceholder={additionalPasswordPlaceholder}
      additionalSubmitButton={additionalSubmitButton}
      additionalSubmittingButton={additionalSubmittingButton}
      additionalPasswordChangedTitle={additionalPasswordChangedTitle}
      additionalPasswordChangedDescription={additionalPasswordChangedDescription}
      additionalPasswordChangedButtonText={additionalPasswordChangedButtonText}
      additionalPasswordLabel={additionalPasswordLabel}
    />
  )
}
