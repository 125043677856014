import React from "react"
import { Wrapper, InputElement, Label, ErrorMessage } from "./TextFieldStyles"
import { withTextField } from "./withTextfield"

export const TextField = withTextField(({ showLabel, label, layout, name, errors, ...props }) => {
  return (
    <Wrapper layout={layout}>
      <Label htmlFor={name} layout={layout} show={showLabel ? "true" : "false"}>
        {label}
      </Label>
      <InputElement layout={layout} name={name} {...props} />
      {typeof errors === "string" && <ErrorMessage>{errors}</ErrorMessage>}
    </Wrapper>
  )
})
