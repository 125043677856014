import React from "react"

export const withTextField = Component => ({ displayName = "TextField", ...props }) => {
  // const [showLabel, setShowLabel] = useState(!!value)

  // useEffect(() => {
  //   if (value.length > 0 && showLabel !== true) {
  //     setShowLabel(true)
  //   }
  // }, [value, showLabel])

  // useEffect(() => {
  //   if (value.length === 0 && showLabel === true) {
  //     setShowLabel(false)
  //   }
  // }, [value, showLabel])

  Component.displayName = displayName
  return <Component {...props} showLabel={true} />
}
