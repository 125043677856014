import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"
import { TinyBody } from "../Styled/Text"

export const Wrapper = styled.div<LayoutProp>`
  ${tw`relative w-full`}
  ${({ layout }) => {
    if (layout === "address") return tw`mb-1-6`
  }}
`

export const InputElement = styled.input<LayoutProp>`
  ${tw`border-b border-black border-opacity-50 appearance-none  outline-none bg-transparent w-full`}
  ${({ layout }) => {
    if (layout === "address") return tw`pb-1-6 pt-2-6 text-18 leading-1.56`
  }}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      ${tw`text-brown opacity-100 uppercase text-18 md:text-12 tracking-10`}
    }
  `}
`

export const Label = styled.label<LayoutProp & ShowProps>`
  ${tw`absolute top-0 left-0 transition-all duration-500`}
  ${({ show }) => (show === "true" ? tw`visible opacity-100 pointer-events-auto` : tw`invisible opacity-0 pointer-events-none`)}
  ${({ layout }) => {
    if (layout === "address") return tw`text-12 leading-2.08 uppercase tracking-10 text-brown`
  }}
`

type LayoutProp = {
  layout: "address"
}

type ShowProps = {
  show: "true" | "false"
}

export const ErrorMessage = styled(TinyBody)`
  ${tw`-mt-1-2 mb-1-6 text-red`}
`
